import React from 'react';
import ZoomableDraggableImage from "./ZoomableDraggableImage";
import "./App.css";
import overlayUrl from './assets/output-onlinepngtools.png'; // Import the overlay image from the assets folder

const App = () => {
  return (
    <div className="App">
      <ZoomableDraggableImage overlayUrl={overlayUrl} />
    </div>
  );
};

export default App;
